import React from "react";

import "./divider.scss";

interface IProps {
  text?: string;
}

const Dot = () => {
  return (
    <svg stroke="currentColor" fill="currentColor" viewBox="0 0 512 512">
      <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
    </svg>
  );
};

export const Divider = ({ text }: IProps) => {
  return (
    <div className="divider--main">
      <Dot />
      <Dot />
      <Dot />
    </div>
  );
};
